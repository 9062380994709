import * as _ from 'lodash';

// Convert Grafana series into Chartwerk series
export class BarSeries {
  private _seriesList;

  constructor(grafanaSeriesList: any) {
    this._seriesList = this._updateSeriesListWithChartwerkParams(grafanaSeriesList);
  }

  getChartwerkSeries(): any[] {
    return this._seriesList;
  }

  private _updateSeriesListWithChartwerkParams(series: any[]): any[] {
    return _.map(series, (serie: any, idx: number) => {
      return {
        target: serie.alias,
        color: serie.color,
        datapoints: _.map(serie.datapoints, (row) => _.reverse(_.clone(row))),
        alias: serie.label,
      };
    });
  }
}
