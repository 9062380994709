import { AbsoluteTimeRange } from '@grafana/data';

// Convert Grafana options into Chartwerk Bar options
export class BarOptions {
  constructor(private grafanaSeriesList: any[], private changeTimeRange: (timeRange: AbsoluteTimeRange) => void) {
    console.log(this.grafanaSeriesList);
  }

  getChartwerkOptions(): any {
    return {
      axis: {
        x: {
          format: 'time',
        },
        y: {
          format: 'custom',
          range: [-100, 100],
          valueFormatter: (value: any) => {
            return value + '%';
          },
        },
      },
      stacked: false,
      matching: false,
      zoomEvents: {
        scroll: { zoom: { isActive: false }, pan: { isActive: false } },
        mouse: { doubleClick: { isActive: false } },
      },
      renderLegend: false,
      annotations: [
        { key: 'm-1', color: 'red' },
        { key: 'm-2', color: 'green' },
      ],
      eventsCallbacks: {
        zoomIn: (range: any) => {
          this.changeTimeRange({ from: range[0][0], to: range[0][1] });
        },
      },
    };
  }
}
