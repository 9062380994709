import { ValueOptions } from 'types';

import { filterMetricListByAlias } from '../utils';

import * as _ from 'lodash';

// Convert Grafana series into Chartwerk series
export class Series {
  private _seriesList;
  private _selectedSerieName;

  constructor(grafanaSeriesList: any[], private gaugeValueOptions: ValueOptions) {
    if (_.isEmpty(grafanaSeriesList)) {
      throw new Error(`No metrics has been provided`);
    }
    if (_.isEmpty(this.gaugeValueOptions?.metricName)) {
      const serie = _.first(grafanaSeriesList);
      this._seriesList = this._updateSeriesListWithChartwerkParams([serie]);
      return;
    }
    this._selectedSerieName = this.gaugeValueOptions.metricName;

    const filteredSeries = filterMetricListByAlias(grafanaSeriesList, this._selectedSerieName, 'Value');

    this._seriesList = this._updateSeriesListWithChartwerkParams(filteredSeries);
  }

  getChartwerkSeries(): any[] {
    return this._seriesList;
  }

  private _updateSeriesListWithChartwerkParams(series: any[]): any[] {
    return _.map(series, (serie: any, idx: number) => {
      return {
        target: serie.alias,
        color: serie.color,
        datapoints: _.map(serie.datapoints, (row) => _.reverse(_.clone(row))),
        alias: serie.label,
      };
    });
  }
}
