import { FieldNamePicker } from '../../grafana/MatchersUI/FieldNamePicker';

import { isNumber } from '../../utils';

import { GrafanaTheme, StandardEditorProps } from '@grafana/data';
import {
  Button,
  ColorPicker,
  HorizontalGroup,
  IconButton,
  InlineField,
  InlineLabel,
  InlineSwitch,
  Input,
  stylesFactory,
  ThemeContext,
} from '@grafana/ui';

import React from 'react';

import { css } from 'emotion';
import * as _ from 'lodash';

interface Props {
  defaultColor: string;
  arcBackground: string;
  thresholds: ThresholdConfig[];
}

type ThresholdConfig = {
  color: string;
  useMetric: boolean;
  value?: number;
  metricName?: string;
};

const DEFAULT_THRESHOLD: ThresholdConfig = {
  color: '#378372d',
  useMetric: false,
  value: 0,
};

const fieldNamePickerSettings = {
  settings: { width: 24 },
} as any;

export function ThresholdsEditor({ onChange, value, context }: StandardEditorProps<Props>) {
  const config = value;

  const addThreshold = () => {
    config.thresholds.push(_.cloneDeep(DEFAULT_THRESHOLD));
    onChange(config);
  };

  const removeThreshold = (idx: number) => {
    config.thresholds.splice(idx, 1);
    onChange(config);
  };

  const onFieldChange = (field: keyof Props, value: any) => {
    config[field] = value;
    onChange(config);
  };

  const onThresholdFieldChange = (thresholdIdx: number, field: keyof ThresholdConfig, value: any) => {
    // @ts-ignore
    config.thresholds[thresholdIdx][field] = value;
    onChange(config);
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => {
        const styles = getStyles(theme.v1);
        return (
          <div>
            <InlineField label="Default Gauge Color" labelWidth={20}>
              <InlineLabel>
                <ColorPicker
                  color={config.defaultColor}
                  onChange={(val) => onFieldChange('defaultColor', val)}
                  enableNamedColors={false}
                />
              </InlineLabel>
            </InlineField>

            <InlineField label="Arc Background" labelWidth={20}>
              <InlineLabel>
                <ColorPicker
                  color={config.arcBackground}
                  onChange={(val) => onFieldChange('arcBackground', val)}
                  enableNamedColors={false}
                />
              </InlineLabel>
            </InlineField>
            {config.thresholds.map((threshold, thresholdIdx) => {
              return (
                <HorizontalGroup key={thresholdIdx}>
                  <InlineField label="Use metric">
                    <InlineSwitch
                      value={threshold.useMetric}
                      onChange={(evt) => onThresholdFieldChange(thresholdIdx, 'useMetric', evt.currentTarget.checked)}
                    />
                  </InlineField>
                  <InlineField>
                    {threshold.useMetric ? (
                      <FieldNamePicker
                        context={context}
                        value={threshold.metricName as string}
                        onChange={(newVal: any) => onThresholdFieldChange(thresholdIdx, 'metricName', newVal)}
                        item={fieldNamePickerSettings}
                      />
                    ) : (
                      <Input
                        placeholder="value"
                        width={24}
                        value={threshold.value}
                        onChange={(evt) =>
                          onThresholdFieldChange(
                            thresholdIdx,
                            'value',
                            isNumber(evt.currentTarget.value) ? +evt.currentTarget.value : undefined
                          )
                        }
                      />
                    )}
                  </InlineField>
                  <InlineField label="Color">
                    <InlineLabel>
                      <ColorPicker
                        color={threshold.color}
                        onChange={(newVal) => onThresholdFieldChange(thresholdIdx, 'color', newVal)}
                        enableNamedColors={false}
                      />
                    </InlineLabel>
                  </InlineField>
                  <InlineField>
                    <InlineLabel width={0} className={styles.deleteButton}>
                      <IconButton
                        name="trash-alt"
                        onClick={() => removeThreshold(thresholdIdx)}
                        tooltip="Delete Threshold"
                      ></IconButton>
                    </InlineLabel>
                  </InlineField>
                </HorizontalGroup>
              );
            })}
            <Button variant="secondary" onClick={addThreshold}>
              Add Threshold
            </Button>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
}

interface ThresholdsEditorStyles {
  deleteButton: string;
}

const getStyles = stylesFactory((theme: GrafanaTheme): ThresholdsEditorStyles => {
  return {
    deleteButton: css`
      margin-right: 0px !important;
    `,
  };
});
